import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useAppContext }from "contexts/AppContext"


// Storybook
import { Row, Col, Card, CardBody, Container } from "stories/layout";
import { Input, DatePicker } from "stories/forms";
import { Typography, Button, Link, IconButton, Modal } from "stories/components";
import { TableAuto } from "stories/tables"

// App
import { PageWrapper, PageHeader, PageContainer } from "components/Page"
import { httpGetTransactions, httpGetTransactionsExcel, httpGetPrivateStockTransactions, httpGetDiarySummary, httpGetDiarySummaryExcel } from "services/transactions"

import StockMarketSelect from "components/Forms/StockMarketSelect/StockMarketSelect"
import StockSelect from "components/Forms/StockSelect/StockSelect"
import StockPrivateSelect from "components/Forms/StockPrivateSelect/StockPrivateSelect"
import TransactionTypeSelect from "components/Forms/TransactionTypeSelect/TransactionTypeSelect"
import InvestmentTypeSelect from "components/Forms/InvestmentTypeSelect/InvestmentTypeSelect"
import PortfolioSelectStore from "components/Forms/PortfolioSelect/PortfolioSelectStore"
import PortfolioSelect from "components/Forms/PortfolioSelect/PortfolioSelect"
import { useNotification } from "stories/components/Notification"
import CurrencySelect from "components/Forms/CurrencySelect/CurrencySelect"

import { useAuthContext } from "contexts/AuthContext"

import { getDate } from "services/datetime"
import { round, roundScaleDecimals, thousandFormat } from "services/helpers"
import { set } from "date-fns";
import { filterEventStoreDefs } from "@fullcalendar/core";

import Info from "components/Info/Info.js";
import { setIn } from "formik";

const DiaryTransactions = ({match, history, setExportButton}) => {
  
  const { t } = useTranslation();
  const { getTransactionType, getPortfolioCurrency } = useAppContext()
  const portfolio = useStoreState((state) => state.portfolio?.portfolio);

  const getBasicDataObjects = useStoreActions((actions) => actions.stocks.getObjects);
  const myAccount = useStoreState((state) => state.account?.account);
  
  const all_purchases = useStoreState((state) => state.purchases?.objects);
  // const setPurchases = useStoreActions((actions) => actions.purchases.setObjects);
  const all_count = useStoreState((state) => state.purchases?.count);
  // const setCount = useStoreActions((actions) => actions.purchases.setCount);

  const [purchases, setPurchases] = useState([])
  const [count, setCount] = useState(0)

  const { notify } = useNotification();

  
  const [currentDate, setCurrentDate] = useState(moment().format('YYYY-MM-DD'));
  const [diarySummary, setDiarySummary] = useState();

  const portfolios = useStoreState((state) => state.portfolio?.portfolios);
  const portfoliosLoading = useStoreState((state) => state.portfolio?.loading);
  const [selected_currency, setSelectedCurrency] = useState(portfolio?.currency_label)

  
  const handleDateChange = event => {
    setCurrentDate(event.target.value)
  }
  
  // Table
  const [loading, setLoading] = useState(false)
  const [loadingSummary, setLoadingSummary] = useState(false)
  const [checked, setChecked] = useState([])

  const [filters, setFilters] = useState({
    limit: 50,
    offset:0,
    ordering: "-date_transaction",
    // stock_market: "XHEL",
    portfolio: [portfolio?.id],
    selected_portfolios: [{id:portfolio?.id, value:portfolio?.name, name:portfolio?.name}],
    type: null,

  })

  useEffect(() => {
    setSelectedCurrency(portfolio?.currency_label)
    // setFilters({selected_portfolios:[{id:portfolio?.id, value:portfolio?.name, name:portfolio?.name}]})

    setFilters(s => ({
      ...s,
      portfolio: [portfolio?.id],
      selected_portfolios: [{id:portfolio?.id, value:portfolio?.name, name:portfolio?.name}]
    }))
    
    //only if portfolio is different than in filters
    if (portfolio?.id != filters?.portfolio){
      getTransactions(portfolio?.id)
    }
  }, [portfolio])




    //----------------------------UPDATE securities ----------------------------------------------------
  useEffect(() => {
    if (true) {
      // console.log("PRIVATE basic_data")
      // getPrivateBasicDataObjects()

      let param ={
        ...filters,
        stock_market:filters?.stock_market,
        related_model_type: filters?.related_model_type,
        all_related_model_types: true
      }

      if (filters?.related_model_type == "30" ){
        param ={...param, type: null}
      }

      //if related_model_type is not in [10, 20, 30, 40] then it is subs
      if (filters?.related_model_type !== "10" && filters?.related_model_type !== "20" && filters?.related_model_type !== "30" && filters?.related_model_type !== "40" && filters?.related_model_type !== "" && filters?.related_model_type !== null && filters?.related_model_type !== undefined  ){
        param ={...param, related_model_type: "30", second_type: filters?.related_model_type}
      }

      getBasicDataObjects(param)
    }
    else {
    getBasicDataObjects({stock_market:filters?.stock_market, all_related_model_types: true})
    }
    // if (values)
    // setFieldValue('stock', null)
  }, [filters?.stock_market, filters.related_model_type, filters?.fund_type, filters?.portfolio, filters?.selected_portfolios])
  
  const getTransactions = (portfolio_id=null, both=true) => {

    // let portfolio = [];
    // if (filters.portfolio) {
    //   portfolio = filters.portfolio.map(item => {
    //       console.log(item)
    //       return {
    //           portfolio: item?.id ? item?.id: item
    //       }
    // })  
    // }

    // type can be list of types items get only item value and create list of values
    let param_type = []
    if (filters?.type && filters?.type.length > 0){
      param_type = filters?.type.map(item => item.value)
    }



    let params = {
      ...filters,
      portfolio: portfolio_id? [portfolio_id]: filters?.portfolio,
      date_transaction__date__gte: filters?.date_transaction__date__gte ? moment(filters?.date_transaction__date__gte).format("YYYY-MM-DD") : undefined,
      sdate_transaction__date__lte: filters?.date_transaction__date__lte ? moment(filters?.date_transaction__date__lte).format("YYYY-MM-DD") : undefined,
      stock_market: filters?.stock_market ? filters?.stock_market : undefined,
      selected_portfolios: null,
      // selected_currency: portfolio_id? portfolio?.currency_label:selected_currency=="nothing"?null:selected_currency,
      selected_currency: portfolio_id? portfolio?.currency_label:selected_currency,
      convert_values_to_target_currency: (selected_currency && selected_currency=="nothing")?false:true,
      type__in: param_type,
      type: param_type,
    }
    if (filters?.stock?.id && filters?.stock?.id !== "") {
      // separete related_model_type and related_model_id from stock.id using "," first part is related_model_type and second is related_model_id
      const related_model_type = filters?.stock?.id?.split(",")[0]
      const related_model_id = filters?.stock?.id?.split(",")[1]
      params = {
        ...params,
        related_model_type: related_model_type,
        related_model_id: related_model_id,
      }
      if (related_model_type !== "10" && related_model_type !== "20" && related_model_type !== "30" && related_model_type !== "40" ){
        params ={...params, related_model_type: 30}
      }
    }
    //if related_model_type is not in [10, 20, 30, 40] then it is subs
    if (filters?.related_model_type !== "10" && filters?.related_model_type !== "20" && filters?.related_model_type !== "30" && filters?.related_model_type !== "40" && filters?.related_model_type !== "" && filters?.related_model_type !== null && filters?.related_model_type !== undefined && filters?.stock?.id === undefined ){
      params ={...params, related_model_type: "30", second_type: filters?.related_model_type}
    }

    setLoading(true)


    httpGetTransactions(params).then(res => {

      setPurchases(res?.data?.results)
      setCount(res?.data?.count)
        setLoading(false)
    })
    if (both){
      setLoadingSummary(true)
      httpGetDiarySummary(params).then(response => {
        setDiarySummary(response?.data)
        setLoadingSummary(false)
      }).finally(() => {
        setLoadingSummary(false)
      }
      )
    }


    
  }


  
  useEffect(() => {
    if (portfolio && filters?.limit && filters?.offset != null ) {
      if (!diarySummary){
        getTransactions(null, true)
      }
      else {
      getTransactions(null, null)
      }
    }

  }, [filters?.limit, filters?.offset, filters?.ordering ])
  // }, [filters?.limit, filters?.offset, filters?.ordering, filters?.portfolio, filters?.related_model_type, filters?.stock_market, filters?.date_transaction__date__gte, filters?.date_transaction__date__lte, filters?.type ])
  
  useEffect(() => {
    const selectedPortfolioIds = [];
    let currency = portfolio?.currency_label

    if (filters?.selected_portfolios && filters?.selected_portfolios.length == 1){
      //filter portfolios by selected portfolio
      const selectedPortfolio = portfolios.filter(item => item.id === filters?.selected_portfolios[0]?.id)
      currency = selectedPortfolio[0]?.currency_label
    }
    // if (filters?.selected_portfolios && filters?.selected_portfolios.length > 1){
    //   currency = "EUR"
    // }
    // setSelectedCurrency(currency)
    filters.selected_portfolios.forEach(item => selectedPortfolioIds.push(item.id));
    setFilters(s => ({
      ...s,
      portfolio: selectedPortfolioIds?selectedPortfolioIds:[portfolio?.id],
    }))

  }, [filters.selected_portfolios])


  //-------- Excel export BEGIN --------

  const [loadingExport, setLoadingExport] = useState(false)

  const handleExcelExport = (type, fileType) => {
    
    let param_type = []
    if (filters?.type && filters?.type.length > 0){
      param_type = filters?.type.map(item => item.value)
    }
    let params = {
      ...filters,
      date_transaction__date__gte: filters?.date_transaction__date__gte ? moment(filters?.date_transaction__date__gte).format("YYYY-MM-DD") : undefined,
      sdate_transaction__date__lte: filters?.date_transaction__date__lte ? moment(filters?.date_transaction__date__lte).format("YYYY-MM-DD") : undefined,
      stock_market: filters?.stock_market ? filters?.stock_market : undefined,
      selected_portfolios: null,
      export_excel: true,
      client_timezone_offset: Intl.DateTimeFormat().resolvedOptions().timeZone,
      selected_currency: selected_currency,
      type__in: param_type,
      type: param_type,
      export_type: fileType,
      
    }
    if (filters?.stock?.id && filters?.stock?.id !== "") {
      // separete related_model_type and related_model_id from stock.id using "," first part is related_model_type and second is related_model_id
      const related_model_type = filters?.stock?.id?.split(",")[0]
      const related_model_id = filters?.stock?.id?.split(",")[1]
      params = {
        ...params,
        related_model_type: related_model_type,
        related_model_id: related_model_id,
     
      }
      if (related_model_type !== "10" && related_model_type !== "20" && related_model_type !== "30" && related_model_type !== "40" ){
        params ={...params, related_model_type: 30}
      }
    }
    //if related_model_type is not in [10, 20, 30, 40] then it is subs
    if (filters?.related_model_type !== "10" && filters?.related_model_type !== "20" && filters?.related_model_type !== "30" && filters?.related_model_type !== "40" && filters?.related_model_type !== "" && filters?.related_model_type !== null && filters?.related_model_type !== undefined && filters?.stock?.id === undefined ){
      params ={...params, related_model_type: "30", second_type: filters?.related_model_type}
    }

    // params["selected_currency"] = selected_currency

    
    let fileName = "investime_diary_summary_export.xlsx";
    if (fileType === "pdf") {
      fileName = "investime_diary_summary_export.pdf";
    }
    if (type === "transactions") {
      fileName = "investime_diary_transactions_export.xlsx";
      if (fileType === "pdf") {
        fileName = "investime_diary_transactions_export.pdf";
      }
    }
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    
    setLoadingExport(true)

    if (fileType === "pdf") {
      notify({ title:t("Viedään PDF..."), icon: "fas fa-file-export"})
    }
    else{
      notify({ title:t("Viedään Exceliin..."), icon: "fas fa-file-export"})

    }

    let method = httpGetDiarySummaryExcel
    if (type === "transactions") {
      method = httpGetTransactionsExcel
    }
    method(params).then(response => {
      // httpGetDiarySummaryExcel(params).then(response => {
      let file = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      if (fileType === "pdf") {
        file = new Blob([response.data], {type: 'application/pdf'});
      }
      let fileURL = window.URL.createObjectURL(file);
      a.href = fileURL;
      a.download = fileName;
      a.click();
    })
    .finally(() => {
      setLoadingExport(false)
    })

  }


  //-------- Excel export END --------



  
  const headers = [
    { label: t("diary_transactions_id", "ID"), key: "id", sortable: true},
    { label: t("diary_transactions_date", "Päivä"), key: "date_transaction", sortable: true },
    { label: t("diary_transactions_batch_id", "Erän ID"), key: "batch_id", sortable: true},
    { label: t("diary_transactions_type", "Tyyppi"), key: "type", sortable: true},
    { label: t("diary_transactions_symbol", "Tunnus"), key: "stock__ticker_symbol", sortable: true},
    { label: t("diary_transactions_name", "Nimi"), key: "stock__name", sortable: true},
    // { label: t("diary_transactions_stock_market", "Pörssi"), key: "stock_market", sortable: true},
    // { label: t("diary_transactions_balance", "Saldo"), key: "balance"},
    { label: t("diary_transactions_quantity", "Määrä"), key: "quantity", sortable: true},
    { label: t("diary_transactions_price", "Hinta/kpl"), key: "price", sortable: true},
    { label: t("diary_transactions_price_total", "Hinta yht."), key: "price_total", sortable: true},
    { label: t("diary_transactions_commission_total", "Kulut yht."), key: "commission_total", sortable: true},
    { label: t("diary_transactions_value_total", "Yhteensä"), key: "value_total", sortable: true},
    { label: t("diary_transactions_currency", "Valuutta"), key: "currency_label", sortable: true},
    { label: t("diary_transactions_portfolio", "Salkku"), key: "portfolio"},
    { label: t("diary_transactions_user", "Tehnyt"), key: "user__full_name"},
    // { label: t("diary_transactions_batch", "Erästä jäljellä"), key: "batch"},
    // { label: t("diary_transactions_original_batch", "Erän alkup. koko*"), key: "original_batch"},
    { label: t("diary_transactions_comment", "Kommentti"), key: "notes"},
    // { label: t("diary_transactions_balance", "Balance"), key: "balance"},
    // { label: t("diary_transactions_actions", ""), key: "actions"},
  ];
  const SummaryHeaders = [
    { label: t("diary_transactions_type", "Tapahtuman tyyppi"), key: "type", sortable:false},
    // { label: t("diary_transactions_label", "Tunnus"), key: "stock__ticker_symbol", sortable: true},
    { label: t("diary_transactions_name", "Nimi"), key: "stock__name", sortable: false},
    // { label: t("diary_transactions_balance", "Saldo"), key: "balance"},
    { label: t("diary_transactions_quantity", "Määrä kpl"), key: "quantity", sortable: false},
    { label: t("diary_transactions_commission_total", "Kulut"), key: "commission_total", sortable: false},
    { label: t("diary_transactions_summary_price_total", "Hankinta/myyntihinta"), key: "price_total", sortable: false},
    { label: t("diary_transactions_value_total", "Yhteensä"), key: "value_total", sortable: false},
    { label: t("diary_transactions_currency", "Valuutta"), key: "currency_label", sortable: false},
    // { label: t("diary_transactions_actions", ""), key: "actions"},
  ];
  
  const paginationPrevious = () => {
    let offset = filters?.offset - filters?.limit;
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const paginationNext = () => {
    let offset = filters?.offset + filters?.limit;
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const getTableRows = useCallback(() => {
    return purchases && purchases.map((row, index) => {
      // const transactionTypeValue = getTransactionType(row?.type)?.value
      // let transactionTypeCell;


      // if (transactionTypeValue === "Buy") {
      //   transactionTypeCell = (<span className="text-danger font-weight-bold">{t("transactionTypeValue_2", transactionTypeValue)}</span>)
      // }
      // else if (transactionTypeValue === "Sell") {
      //   transactionTypeCell = (<span className="text-success font-weight-bold">{t("transactionTypeValue_3", transactionTypeValue)}</span>)
      // }
      // else if (transactionTypeValue === "Split") {
      //   transactionTypeCell = (<span className="text-info font-weight-bold">{t("transactionTypeValue_20", transactionTypeValue)}</span>)
      // }
      // else {
      //   transactionTypeCell = (<span className="text-info font-weight-bold">{t("transactionTypeValue_41", transactionTypeValue)}</span>)
      // }

      const transactionTypeValue = getTransactionType(row?.type)?.value
      let transactionTypeCell;

      if (transactionTypeValue === "Buy") {
        transactionTypeCell = (<span className="text-danger font-weight-bold">{t("transactionTypeValue_2", transactionTypeValue)}</span>)
      }
      else if (transactionTypeValue === "Sell") {
        transactionTypeCell = (<span className="text-success font-weight-bold">{t("transactionTypeValue_3", transactionTypeValue)}</span>)
      }
      else if (transactionTypeValue === "Split") {
        transactionTypeCell = (<span className="text-info font-weight-bold">{t("transactionTypeValue_20", transactionTypeValue)}</span>)
      }
      else if (transactionTypeValue === "Dividend") {
        transactionTypeCell = (<span className="text-info font-weight-bold">{t("transactionTypeValue_30", transactionTypeValue)}</span>)
      }
      else if (transactionTypeValue === "DividendOwn") {
        transactionTypeCell = (<span className="text-info font-weight-bold">{t("transactionTypeValue_32", transactionTypeValue)}</span>)
      }
      else if (row?.type === 50) {
        transactionTypeCell = (<span className="text-info font-weight-bold">{t("transactionTypeValue_50", "Muu kulu/tuotto")}</span>)
      }
      else {
        transactionTypeCell = (<span className="text-info font-weight-bold">{t("transactionTypeValue_41", transactionTypeValue)}</span>)
      }







      const stockNameCell = (
        <><span className={!row?.type? "font-weight-bold": null}>{row?.related_model?.name}</span></>
      )
      return {
        ...row,
        quantity: {
          content: thousandFormat(roundScaleDecimals(row?.quantity, 2)),
          className: "text-right"
        },
        price: {
          content: thousandFormat(roundScaleDecimals(Math.abs(row?.price), 2)),
          className: "text-right"
        },
        price_total: {
          content: thousandFormat(roundScaleDecimals(row?.price_total, 2)),
          className: "text-right"
        },
        commission_total: {
          content: thousandFormat(roundScaleDecimals(row?.commission_total, 2)),
          className: "text-right"
        },
        value_total: {
          content: thousandFormat(roundScaleDecimals(row?.value_total, 2)),
          className: "text-right"
        },

        batch_id: row?.batch_id ? row?.batch_id : row?.id,
        original_batch: thousandFormat(row?.original_batch_balance),
        // date_transaction: getDate(row?.date_transaction),
        date_transaction: (
          <>
          {row?.original_purchase_date? <> {getDate(row?.original_purchase_date) + " " }<span className="text-gray ">({getDate(row?.date_transaction)})*</span></>:getDate(row?.date_transaction)}
          </>
        ),
        portfolio: row?.portfolio?.name,
        // currency_label: row?.related_model?.currency_label,
        currency_label: row?.currency_label,
        type: transactionTypeCell,
        stock__ticker_symbol: row?.related_model?.ticker_symbol,
        stock__name: stockNameCell,
        stock_market: row?.stock_market,
        user__full_name: row?.user?.full_name,
        batch: row?.batch_balance,
        actions:(
                    <><Button className="btn-sm" color="secondary" >{t("edit_button","Muokkaa")}</Button></>
                ) 
      }
    })
  }, [purchases, currentDate])
  

  const getSummaryTableRows = useCallback(() => {
    //Define summary variables
    let buy_total_quantity_summary = 0
    let buy_total_price_summary = 0
    let buy_total_commission_summary = 0
    let buy_total_value_summary = 0

    let sell_total_quantity_summary = 0
    let sell_total_price_summary = 0
    let sell_total_commission_summary = 0
    let sell_total_value_summary = 0

    let dividend_total_quantity_summary = 0
    let dividend_total_price_summary = 0
    let dividend_total_commission_summary = 0
    let dividend_total_value_summary = 0

    let exchange_total_quantity_summary = 0
    let exchange_total_price_summary = 0
    let exchange_total_commission_summary = 0
    let exchange_total_value_summary = 0

    let other_total_quantity_summary = 0
    let other_total_price_summary = 0
    let other_total_commission_summary = 0
    let other_total_value_summary = 0




    //MAIN ROWS
    let rows = []
    const summary_name_sell = t("diary_summary_sell_total", "Myynnit yhteensä")
    const summary_name_buy = t("diary_summary_buy_total", "Ostot yhteensä")
    const summary_name_dividend = t("diary_summary_dividend_total", "Osingot yhteensä")
    const summary_name_exchange = t("diary_summary_exchange_total", "Vaihdot ulos yhteensä")
    const summary_name_other = t("diary_summary_other_total", "Muut kulut/tuotot yhteensä")

    // if (diarySummary?.sell && diarySummary.buy){
    //   rows = type === "Sell"?  [...diarySummary?.sell] : type==="Buy"? [...diarySummary?.buy] : [];
      
    // } 



    //----- SELL ROWS -----

    diarySummary?.sell && diarySummary?.sell.map((row) => {
      sell_total_quantity_summary += row?.total_quantity
      sell_total_price_summary += row?.total_price
      sell_total_commission_summary += row?.total_commission
      sell_total_value_summary += row?.total_value
      rows.push(row)
    } ) 

    diarySummary?.sell.length > 0 && rows.push({
          related_model: {name:summary_name_sell},
          total_quantity: sell_total_quantity_summary,
          total_price: roundScaleDecimals(sell_total_price_summary, 2),
          total_commission: roundScaleDecimals(sell_total_commission_summary, 2),
          total_value: roundScaleDecimals(sell_total_value_summary, 2),
          type: "summary"
        })

    diarySummary?.sell.length > 0 && rows.push({
      // total_quantity: 0,
      // total_price: "",
      // total_commission: "",
      // total_value: "",
      type: "space",
      related_model: {name:"  -  "}
    })

    //----- EXCHANGE ROWS -----
    diarySummary?.exchange && diarySummary?.exchange.map((row) => {
      exchange_total_quantity_summary += row?.total_quantity
      exchange_total_price_summary += row?.total_price
      exchange_total_commission_summary += row?.total_commission
      exchange_total_value_summary += row?.total_value
      rows.push(row)
    } )

    diarySummary?.exchange.length > 0 && rows.push({
      related_model: {name:summary_name_exchange},
      total_quantity: exchange_total_quantity_summary,
      total_price: roundScaleDecimals(exchange_total_price_summary, 2),
      total_commission: roundScaleDecimals(exchange_total_commission_summary, 2),
      total_value: roundScaleDecimals(exchange_total_value_summary, 2),
      type: "summary"
    })

    diarySummary?.exchange.length > 0 && rows.push({
      // total_quantity: "",
      // total_price: "",
      // total_commission: "",
      // total_value: "",
      type: "space",
      related_model: {name:"  -  "}
    })


    //----- BUY ROWS -----
    diarySummary?.buy && diarySummary?.buy.map((row) => {
      buy_total_quantity_summary += row?.total_quantity
      buy_total_price_summary += row?.total_price
      buy_total_commission_summary += row?.total_commission
      buy_total_value_summary += row?.total_value
      rows.push(row)
    } ) 

    diarySummary?.buy.length >0 && rows.push({
      related_model: {name:summary_name_buy},
      total_quantity: buy_total_quantity_summary,
      total_price: roundScaleDecimals(buy_total_price_summary, 2),
      total_commission: roundScaleDecimals(buy_total_commission_summary, 2),
      total_value: roundScaleDecimals(buy_total_value_summary, 2),
      type: "summary"
    })

    diarySummary?.sell.length > 0 && rows.push({
      // total_quantity: "",
      // total_price: "",
      // total_commission: "",
      // total_value: "",
      type: "space",
      related_model: {name:"  -  "}
    })







    //----- SUMMARY ROWS with exchange -----
    diarySummary && rows.push({
      related_model: {name:t("diary_summary_all_summary","Kaikki ostot ja myynnit yhteensä")},
      total_quantity: buy_total_quantity_summary - sell_total_quantity_summary - exchange_total_quantity_summary,
      total_price: roundScaleDecimals(buy_total_price_summary + sell_total_price_summary + exchange_total_price_summary, 2),
      total_commission: roundScaleDecimals(buy_total_commission_summary + sell_total_commission_summary + exchange_total_commission_summary, 2),
      total_value: roundScaleDecimals(buy_total_value_summary + sell_total_value_summary + exchange_total_value_summary, 2),
      // total_commission: round(buy_total_commission_summary, 2) + round(sell_total_commission_summary, 2) + round(exchange_total_commission_summary, 2),
      // total_value: round(buy_total_value_summary, 2) + round(sell_total_value_summary, 2) + round(exchange_total_value_summary, 2),
      type: "summary"
    })

    //----- OTHER ROWS -----
    diarySummary?.other.length > 0 && rows.push({
      type: "space",
      related_model: {name:"  -  "}
    })

    diarySummary?.other && diarySummary?.other.map((row) => {
      other_total_quantity_summary += row?.total_quantity
      other_total_price_summary += row?.total_price
      other_total_commission_summary += row?.total_commission
      other_total_value_summary += row?.total_value
      rows.push(row)
    } )

    diarySummary?.other.length > 0 && rows.push({
      related_model: {name:summary_name_other},
      total_quantity: other_total_quantity_summary,
      total_price: roundScaleDecimals(other_total_price_summary, 2),
      total_commission: roundScaleDecimals(other_total_commission_summary, 2),
      total_value: roundScaleDecimals(other_total_value_summary, 2),
      type: "summary"
    })
    diarySummary?.other.length > 0 && rows.push({
      // total_quantity: "",
      // total_price: "",
      // total_commission: "",
      // total_value: "",
      type: "space",
      related_model: {name:"  -  "}
    })


    //----------------- DIVIDEND ROWS -----------------
    diarySummary?.dividend.length > 0 && rows.push({
      type: "space",
      related_model: {name:"  -  "}
    })
    diarySummary?.dividend.length && diarySummary?.dividend.map((row) => {
      dividend_total_quantity_summary += row?.total_quantity
      dividend_total_price_summary += row?.total_price
      dividend_total_commission_summary += row?.total_commission
      dividend_total_value_summary += row?.total_value
      rows.push(row)
    } )
    diarySummary?.dividend.length >0 && rows.push({
      related_model: {name:summary_name_dividend},
      total_quantity: dividend_total_quantity_summary,
      total_price: roundScaleDecimals(dividend_total_price_summary, 2),
      total_commission: roundScaleDecimals(dividend_total_commission_summary, 2),
      total_value: roundScaleDecimals(dividend_total_value_summary, 2),

      type: "summary"
    })
    diarySummary?.dividend.length > 0 && !diarySummary?.sell.length > 0 && rows.push({
      type: "space",
      related_model: {name:"  -  "}
    })





  //  //----- SUMMARY ROWS -----
  //  diarySummary && rows.push({
  //   related_model: {name:t("diary_summary_all_summary","Kaikki yhteensä")},
  //   total_quantity: buy_total_quantity_summary - sell_total_quantity_summary,
  //   total_price: roundScaleDecimals(buy_total_price_summary, 2) + roundScaleDecimals(sell_total_price_summary, 2),
  //   total_commission: roundScaleDecimals(buy_total_commission_summary, 2) + roundScaleDecimals(sell_total_commission_summary, 2),
  //   total_value: roundScaleDecimals(buy_total_value_summary, 2) + roundScaleDecimals(sell_total_value_summary, 2),
  //   type: "summary"
  // })



    return diarySummary && rows.map((row, index) => {
      const transactionTypeValue = row?.type
      let transactionTypeCell;
      if (transactionTypeValue == "2") {
        transactionTypeCell = (<span className="text-danger font-weight-bold">{t("transactionTypeValue_2", transactionTypeValue)}</span>)
      }
      else if (transactionTypeValue == "3") {
        transactionTypeCell = (<span className="text-success font-weight-bold">{t("transactionTypeValue_3", transactionTypeValue)}</span>)
      }
      else if (transactionTypeValue == 30) {
        transactionTypeCell = (<span className="text-info font-weight-bold">{t("transactionTypeValue_30", transactionTypeValue)}</span>)
      }
      else if (transactionTypeValue == 41) {
        transactionTypeCell = (<span className="text-info font-weight-bold">{t("transactionTypeValue_41", transactionTypeValue)}</span>)
      }
      else if (row?.type === 50) {
        transactionTypeCell = (<span className="text-info font-weight-bold">{t("transactionTypeValue_50", "Muu kulu/tuotto")}</span>)
      }
      else {
        transactionTypeCell = (<span className="text-info font-weight-bold"></span>)
      }


      
      const stockNameCell = (
        <><span className={row?.type== "summary" ? "font-weight-bolder": null} style={{opacity: row?.type == "space" ? 0 : 1 }}>{row?.related_model?.name}</span></>
      )
      const comissionTotalCell = (
        <><span className={row?.type== "summary" ? "font-weight-bolder": null} style={{opacity: row?.type == "space" ? 0 : 1 }}>{!row?.total_commission? 0:thousandFormat(roundScaleDecimals(row?.total_commission, 2))}</span></>
      )
      const valueTotalCell = (
        <><span className={row?.type== "summary" ? "font-weight-bolder": null} style={{opacity: row?.type == "space" ? 0 : 1 }}>{!row?.total_value? 0:thousandFormat(roundScaleDecimals(row?.total_value, 2))}</span></>
      )
      const priceTotalCell = (
        <><span className={row?.type== "summary" ? "font-weight-bolder text-right": "text-right"}style={{opacity: row?.type == "space" ? 0 : 1 }}>{!row?.total_price ? 0:thousandFormat(roundScaleDecimals(row?.total_price, 2))}</span></>
      )
      const quantityCell = (
        <><span className={row?.type== "summary" ? "font-weight-bolder text-right": row?.type =="space"? "pt-3":null} style={row?.type == "space" ?{color: "transparent !important"}: {}}>{row?.total_quantity && thousandFormat(roundScaleDecimals(row?.total_quantity, 2)) }</span></>

      )
      const currencyLabelCell = (
        <><span className={row?.type== "summary" ? "font-weight-bolder": row?.type =="space"? "pt-3":null} style={row?.type == "space" ?{color: "transparent !important"}: {}}>{row?.type !== "space" ? filters?.portfolio.length > 1? selected_currency: selected_currency:null}</span></>

      )
      return {
        ...row,
        quantity: {
          content: quantityCell, 
          className: "text-right"
        },
        price_total: {
          content: priceTotalCell,
          className: "text-right"
        },
        commission_total: {
          content: comissionTotalCell,
          className: "text-right"
        },
        value_total: {
          content: valueTotalCell,
          className: "text-right"
        },

        currency_label: currencyLabelCell,
        type: transactionTypeCell,
        stock__ticker_symbol: row?.related_model?.ticker_symbol,
        stock__name: stockNameCell,
        stock_market: row?.stock_market,
        actions:(
                    <><Button className="btn-sm" color="secondary" >{t("edit_button","Muokkaa")}</Button></>
                ) 
      }
    })
  }, [diarySummary, currentDate])

   

   



  
  // Filters
  
  const handleChange = ({target}, child) => {
    setFilters(s => ({
      ...s,
      [target?.name]: target.value
    }))
   

  }

  const setFieldValue = (name, value) => {
    setFilters(s => ({
      ...s,
      [name]: value
    }))
  }
  const setSelectFieldValue = (name, value) => {
    setFilters(s => ({
      ...s,
      [name]: value    
    }))
  }
  
  const getFormikInputProps = useCallback(
    name => ({
      name,
      value: filters[name],
      onChange: handleChange
    }),
    [filters, handleChange]
  );
  
  const handleStockMarketChange = ({target}) => {
    
    setFilters(s => ({
      ...s,
      stock_market: target.value,
      stock: null
    }))
  }

  const handleInvestmentTypeChange = ({target}) => {
    if (target.value !== "10" ) {
      setFilters(s => ({
        ...s,
        related_model_type: target.value,
        stock_market: undefined,
        stock: null
      }))

    }
    else {
    setFilters(s => ({
      ...s,
      related_model_type: target.value,
      stock: null
    }))
  }
  }

  const [modalEditOpen, setModalEditOpen] = useState(false)
  useEffect(() => {
    // if (portfolio == null || portfolio == undefined) {
    //   setModalEditOpen(true)
    // }

    if (portfoliosLoading) {
      setModalEditOpen(false)
    }
    else {
    setModalEditOpen(portfolios.length === 0 ? true : false)
    }
  }, [portfolios])

  const [modalEditId, setModalEditId] = useState()
  
  const toggleModalEdit = () => {
    setModalEditOpen(s => !s);
  }
  
  const openModalEdit = (id) => {
    setModalEditOpen(true)
    setModalEditId(id);
  }
  
  const modalEditResolve = () => {
    setModalEditOpen(false);
    setModalEditId(null);
    
  }
  
  const modalEditReject = () => {
    setModalEditOpen(false);
    setModalEditId(null);
  }

  
  const getStockSelect = useCallback(() => {

    return (
      <StockSelect
        {...getFormikInputProps("stock")}
        className="select_multi_select_large"

        label={t("purchase_order_edit_asset_stock", "Arvopaperi")}
        setFieldValue={setSelectFieldValue}
        showAll={true}
        setValueToSelect={true} 
        setFieldValueReturnId={false}
        ratesView={false}
        focused={true}
      />
    )
  }, [filters?.stock_market, filters?.related_model_type, filters?.portfolio, filters?.selected_portfolios, filters?.stock])

  const getInvestmentTypeSelect = useCallback(() => {
    return <InvestmentTypeSelect name="related_model_type" onChange={handleInvestmentTypeChange} showSubsOptions={true} value={filters.related_model_type} />
  }, [filters?.related_model_type, myAccount, filters?.portfolio, filters?.selected_portfolios])


  const getCurrencySelect = useCallback(() => {
    let default_currencys = ["EUR","USD","SEK","nothing"]

    const selected_portfolios_currencies = filters?.selected_portfolios.map(item => 
      {const portfolio = portfolios.find(portfolio => portfolio.id === item?.id)
        return portfolio?.currency_label
      }
    )
    //Add only selected currency to default currencys
    // default_currencys = [...default_currencys, portfolio?.currency_label]
    default_currencys = [...default_currencys, ...selected_portfolios_currencies]

    //order by default_currencys
    default_currencys = default_currencys.sort()

    return (
      <CurrencySelect 
      name='currency'
      value={selected_currency}
      onChange={(event) => setSelectedCurrency(event.target.value)}
      label={t("Valuutta")} 
      disabled={false}
      useOnlyList = {default_currencys}
      add_nothing={true}
      // useOnlyList = {filters?.selected_currency}
      />
    )
  }, [selected_currency, portfolio, filters?.selected_portfolios])
  

  useEffect(() => {
  
    setExportButton(    
      <div className="d-flex flex-wrap">
        <div className="p-1">
          <Button color="secondary" size="sm" disabled={loadingExport} onClick={() => handleExcelExport("summary", "excel")}>
              <i className="fas fa-file-excel"></i>&nbsp;
              {t("diary_summary_excel_export","Vie yhteenveto (Excel)")}
          </Button>
        </div>
        <div className="p-1">
          <Button color="secondary" size="sm" disabled={loadingExport} onClick={() => handleExcelExport("transactions", "excel")}>
              <i className="fas fa-file-excel"></i>&nbsp;
              {t("diary_trx_excel_export","Vie kirjaukset (Excel)")}
          </Button>
        </div>
        <div className="p-1">
          <Button color="secondary" size="sm" disabled={loadingExport} onClick={() => handleExcelExport("summary", "pdf")}>
              <i className="fas fa-file-pdf"></i>&nbsp;
              {t("diary_summary_pdf_export","Vie yhteenveto (pdf)")}
          </Button>
        </div>
        <div className="p-1">
          <Button color="secondary" size="sm" disabled={loadingExport} onClick={() => handleExcelExport("transactions", "pdf")}>
              <i className="fas fa-file-pdf"></i>&nbsp;
              {t("diary_trx_pdf_export","Vie kirjaukset (pdf)")}
          </Button>
        </div>
      </div>           
        )
    }, [filters, selected_currency])



  
  return (
    
    <div className="sk_transaktions_list">
    <PageContainer>

      <div className="d-flex justify-content-between mb-3">

        <div className="d-flex text-right align-items-center">
        
   
          {/* 
          <Input label="Kurssipäivä" name="stock_date" type="date" value={currentDate} onChange={handleDateChange} />
          */}
        </div>
      </div>








      <Container fluid className="custom-no-padding  mt-3 m-0 mr-0 px-0" style={{paddingLeft:"0px !important", paddingRight: "0px !important"}}>
        <Row className=" m-0 p-0">
            
          <Col className="m-0 p-0">

          <div className="d-flex flex-wrap ">
              <div className="d-flex justify-content-start align-items-end py-2 pr-2 mr-3" style={{minWidth: '200px',  maxWidth: '250px'}}>
                <PortfolioSelect 
                  name="selected_portfolios"
                  label={t("Salkku")}
                  
                  setFieldValue={setFieldValue}
                  multiple={true}
                  placeholder={t("select_portfolio", "Valitse salkku")}
                  onChange={handleChange}
                  value= {filters.selected_portfolios}
                />
              </div>
              <div className="d-flex justify-content-start align-items-end py-2 pr-2 mr-3" style={{minWidth: '200px',  maxWidth: '250px'}}>
                {getStockSelect()}
              </div>
              <div className="d-flex justify-content-start align-items-end py-2 pr-2 mr-3" style={{minWidth: '250px',  maxWidth: '320px'}}>
                {myAccount?.license && getInvestmentTypeSelect()}
              </div>
              {filters?.related_model_type == 10 &&
              <div className="d-flex justify-content-start align-items-end py-2 pr-2 mr-3" style={{minWidth: '150px', maxWidth:'140px'}}>
                 <StockMarketSelect onChange={handleStockMarketChange} value={filters?.stock_market} showPrivate={false} />
              </div>
              }
              <div className="d-flex justify-content-start align-items-end py-2 pr-2 mr-3" >
                
                <div className="mt-auto" style={{minWidth: '200px',  maxWidth: '200px'}}>

                <TransactionTypeSelect name="type" label={t("diary_transaction_type", "Tyyppi")} isMulti={true} placeholder={t('Kaikki')} onChange={handleChange} value={filters?.type} setFilters={setFilters}/>
                </div>
              </div>
              <div className="d-flex justify-content-start align-items-end py-2 pr-2 mr-3" style={{minWidth: '120px', maxWidth: '130px'}}>
                <div>
                  <label className="mb-1 pr-1 header-bold">{t("date_transaction_from", "Päivä alkaen")}</label>
                  <DatePicker
                    className="form-control"
                    selected={filters?.date_transaction__date__gte}
                    name="date_transaction__date__gte"
                    onChange={(date) => setFieldValue("date_transaction__date__gte", date)}
                    showYearDropdown
                    dateFormat="d.M.yyyy"
                    isClearable={true}
                    placeholderText={t("Kaikki")}

                  />
                  {/*
                    <Input label={t("date_transaction_from", "Päivä alkaen")} name="date_transaction__date__gte" type="date" value={filters?.date_transaction__date__gte} onChange={handleChange} />
                  */}
                </div>
              </div>
              <div className="d-flex justify-content-row align-items-end py-2 pr-2 mr-3" style={{minWidth: '120px', maxWidth: '130px'}}>
                <div >
                  <label className="mb-1 pr-1 header-bold">{t("date_transaction_to", "Päivä päättyen")}</label>
                  <DatePicker
                    className="form-control"
                    selected={filters?.date_transaction__date__lte}
                    name="date_transaction__date__lte"
                    onChange={(date) => setFieldValue("date_transaction__date__lte", date)}
                    showYearDropdown
                    dateFormat="d.M.yyyy"
                    isClearable={true}
                    placeholderText={t("Kaikki")}

                  
                  />
                </div>
              </div>
              <div className="d-flex pr-1 py-2 align-items-end mr-3" >
                {getCurrencySelect()}
              </div>
              <div className="d-flex justify-content-start align-items-end py-2 pr-2  ml-0 mr-3 ml-xxxl-auto mr-xxxl-0" style={{minWidth: '50px'}}>
                <Button name="profit_search_button" className="mt-2" color="secondary" onClick={() => getTransactions()}>{t("Hae")}</Button>
              </div>


          </div>

          </Col>


        </Row>
      </Container>
















      
      


      
      <TableAuto
        showRowNumber={true}
        showId={false}
        checkboxes={false}
        headers={headers}
        headerColor={"light"}
        rows={getTableRows()}
        loading={loading}
        pagination={true}
        paginationPrevious={paginationPrevious}
        paginationNext={paginationNext}
        paginationOffset={filters?.offset}
        paginationLimit={filters?.limit}
        paginationCount={count}
        filters={filters}
        setFilters={setFilters}
        checked={checked}
        setChecked={setChecked}
      />
      
      <Row>
        <Col>
        <div className="d-flex justify-content-start mb-2">
          <div className="p-2 ml-auto">
            {/* <Button color="secondary" size="sm" disabled={loadingExport} onClick={handleExcelExport}>
              <i className="fas fa-file-excel"></i>&nbsp;
              {t("diary_summary_export_excel","Yhteenveto Excel vienti")}
            </Button> */}
          </div>
        </div>
        </Col>
      </Row>

      </PageContainer>
      
      <PageContainer className="bg-lighter">
      <Typography variant="small">{t("total_transactions","Kirjauksia yhteensä")} {count}{t("kpl")}</Typography>
      <Typography bold variant="h3" className="my-3">{t("diary_summary_title","Yhteenveto")}</Typography>

      <Row>
        <Col sm="12" lg="10">
          <TableAuto
            showId={false}
            checkboxes={false}
            headers={SummaryHeaders}
            headerColor={"light"}
            rows={getSummaryTableRows()}
            loading={loadingSummary}

          />

        </Col>
      </Row>
      <Row>
        <Col sm="6">

        </Col>
      </Row>
      </PageContainer>



      <Modal
      // title={modalEditPurchaseId > 0 ? t("Muokkaa ostokirjausta") : t("Uusi ostokirjaus")}
      showHeader={false}
      isOpen={modalEditOpen}
      toggleModal={toggleModalEdit}
      size="modal-lg"
    >
        <Info
        objectId={modalEditId}
        handleResolve={modalEditResolve}
        handleReject={modalEditReject}
      />
    </Modal>



      
      {/*JSON.stringify(stocksData)*/}
    </div>
  );
}

export default DiaryTransactions;
